import * as React from "react";
import { useNavbarStyles } from "./Navbar.styles";
import logo_small from "../../assets/images/logo_small.svg";
import user_icon from "../../assets/images/user_icon.svg";
import { Link } from "react-router-dom";
import { routes } from "../../Routes";
import NavbarContent from "./NavbarContent";
import { useStoreState } from "../../hooks/useStoreState";
import { setNavbarMenuOpen } from "../../store/actions/MenuActions";
import SubMenuDialog from "./sub-menu/SubMenuDialog";
import { RouteComponentProps, withRouter } from "react-router";
import { Settings } from "@material-ui/icons";
import { useAppDispatch } from "../../store/Store";

const Navbar: React.FC<RouteComponentProps<any>> = ({
  location,
}): JSX.Element => {
  const classes = useNavbarStyles({});
  const dispatch = useAppDispatch();
  const menuState = useStoreState().menuState;
  const userState = useStoreState().userState;

  const getProfilePageRoute = () => {
    if (userState.data?.role_id === 3) {
      return routes.profilePageRelative;
    }

    return routes.profilePage;
  };

  const getProfileSettingsPageRoute = () => {
    return routes.profileSettingsPage;
  };

  return (
    <>
      <div className="w-full flex flex-col bg-primary rounded-b-lg text-white text-center font-bold p-4">
          <p className="p-0 m-0 mb-2 w-full">
            Appen lukkes d. 1. marts 2025 - find derefter vores nye selvhjælpsunivers på
          </p>
          <a 
            className="text-white underline underline-offset-2" 
            href="http://ludomani.dk/" 
            target="_blank"
            rel="noreferrer"
          >
            Center for Ludomani
          </a>
      </div>
      <nav className={classes.root}>
        <Link to={routes.home}>
          <img src={logo_small} alt="CFL" />
        </Link>

        <div className={classes.menuArea}>
          <Link
            to={getProfilePageRoute()}
            className={classes.profileButton}
            onClick={() => dispatch(setNavbarMenuOpen(false))}
          >
            <img src={user_icon} alt="Profile" />
          </Link>
          {userState.data?.role_id && userState.data?.role_id !== 3 && (
            <Link
              to={getProfileSettingsPageRoute()}
              className={classes.profileSettingsButton}
              onClick={() => dispatch(setNavbarMenuOpen(false))}
            >
              <Settings className={classes.profileSettingsButtonIcon} />
            </Link>
          )}
          {location.pathname !== routes.home && (
            <button
              className="hamburger"
              type="button"
              onClick={() =>
                dispatch(setNavbarMenuOpen(!menuState.navbarMenuOpen))
              }
            >
              <span
                className={
                  menuState.navbarMenuOpen
                    ? "hamburger-box hamburger--slider is-active"
                    : "hamburger-box hamburger--slider"
                }
              >
                <span className="hamburger-inner" />
              </span>
            </button>
          )}
        </div>

        {menuState.navbarMenuOpen && <NavbarContent />}
        <SubMenuDialog />
      </nav>
    </>
  );
};

export default withRouter(Navbar);
